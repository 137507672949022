@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$mat-mercando-palette-primary: (
    50 : #e4f7f3,
    100 : #bbebe1,
    200 : #8ddecd,
    300 : #5fd0b9,
    400 : #3dc6aa,
    500 : #1bbc9b,
    600 : #18b693,
    700 : #14ad89,
    800 : #10a57f,
    900 : #08976d,
    A100 : #c3ffec,
    A200 : #90ffdb,
    A400 : #5dffcb,
    A700 : #44ffc2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$mat-mercando-palette-accent: (
    50 : #f0f8f0,
    100 : #d9eeda,
    200 : #c0e3c2,
    300 : #a7d8a9,
    400 : #94cf96,
    500 : #81c784,
    600 : #79c17c,
    700 : #6eba71,
    800 : #64b367,
    900 : #51a654,
    A100 : #ffffff,
    A200 : #d4ffd6,
    A400 : #a1ffa5,
    A700 : #88ff8c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$mat-mercando-palette-accent-warn: (
    50 : #fceeee,
    100 : #f7d5d5,
    200 : #f2b9b9,
    300 : #ed9d9d,
    400 : #e98888,
    500 : #e57373,
    600 : #e26b6b,
    700 : #de6060,
    800 : #da5656,
    900 : #d34343,
    A100 : #ffffff,
    A200 : #fff3f3,
    A400 : #ffc0c0,
    A700 : #ffa7a7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$mercando-primary: mat.define-palette($mat-mercando-palette-primary);
$mercando-accent: mat.define-palette($mat-mercando-palette-accent);
$mercando-warn: mat.define-palette($mat-mercando-palette-accent-warn);

$mercando-theme: mat.define-light-theme((
  color: (
    primary: $mercando-primary,
    accent: $mercando-accent,
    warn: $mercando-warn,
  )
));

@include mat.all-legacy-component-themes($mercando-theme);


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.delete-button {
  color:rgb(251, 111, 111);
}

.form-error-msg {
  color: red;
}

.mat-tooltip.tooltip {
  font-size: 16px;
}

.m-0 {
  margin: 0 !important;
}
.font-light {
  font-weight: 300 !important;
}
.home-section {
  padding: 80px 0;
}
.home-section mat-card:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
}
.light-gray {
  background: rgba(0, 0, 0, .024);
}
.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
.section-header {
  margin: 0 0 2rem;
}
.section-header h2 {
  font-size: 34px;
  font-weight: 500;
  margin: 0 0 16px;
}
.section-header p {
  font-size: 16px;
  max-width: 36rem;
  margin: 0;
}
.home-fancy-card {
  border-top: 2px solid #212121;
  transition: all .3s ease;
}

.home-fancy-card:hover {
  border-top: 2px solid #1bbc9b;
}
.home-fancy-card:hover [mat-card-icon] {
  color: #1bbc9b;
}
.home-fancy-card .description {
  font-size: 15px;
  color: #616161;
}
.text-center {
  text-align: center;
}
.text-muted {
  color: rgba(0, 0, 0, .54) !important;
}
.text-muted-white {
  color: rgba(255, 255, 255, .54) !important;
}

/*------ Carousel -------*/
// .ngucarousel {
//   position: relative;
//   max-width: 1100px;
//   margin: 0 auto;
// }
// .ngucarousel-inner {
//   padding: 8px 0 18px;
// }
// .ngucarousel .carousel-left,
// .ngucarousel .carousel-right {
//   position: absolute;
//   top: calc(50% - 28px);
//   background: #ffffff !important;
//   color: rgba(0, 0, 0, .87);
// }
// .ngucarousel .carousel-left {
//   left: -10px;
// }
// .ngucarousel .carousel-right {
//   right: -10px;
// }

// .carousel-point {
//   list-style-type: none;
//   text-align: center;
//   padding: 12px;
//   margin: 0;
//   white-space: nowrap;
//   overflow: auto;
//   box-sizing: border-box;

//   li {
//     display: inline-block;
//     border-radius: 50%;
//     background: #6b6b6b;
//     padding: 5px;
//     margin: 0 4px;
//     transition-timing-function: cubic-bezier(.17,.67,.83,.67);
//     transition: .4s;
//     cursor: pointer;
//   }

//   li.active {
//     box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
//     -webkit-transform: scale(1.7);
//     transform: scale(1.7);
//   }
// }

.article-container-inner-html {
  text-align:center;
}
.article-image-inner-html {
  max-width:100%;
  max-height:100%;
}
.article-icon-inner-html {
  max-height:125px;
}
.article-image-text-inner-html {
  color:grey;
  margin-top:0;
  font-size:15px;
  padding:10px;
}
.article-sup {
  vertical-align: super;
  font-size: 50%;
}
.article-quote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  // quotes: "\201C""\201D""\2018""\2019";
  font-size: 1em;
}
.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

// /* Default theme. ~960B */
// @import '~@vime/core/themes/default.css';

// /* Optional light theme (extends default). ~400B */
// @import '~@vime/core/themes/light.css';

